<template>
  <EuiContainer v-if="user !== undefined">

    <!-- ***DIALOGS*** -->
    <SignDialog :signworkflows="signDialog" :open="isOpen" :hasParaphs="hasParaphs" :hasSigns="hasSigns" @close="signDialog = []"/>
    <es-refuse-dialog :signworkflows="refuseDialog" @closeDialog="closeDialog" v-if="refuseDialog.length" />
    <SaveAsTemplateDialog v-if="saveAsTemplateDialogActive" :signBookId="saveAsTemplateDialogActive.id" :name="saveAsTemplateDialogActive.name" @close="closeSaveAsTemplateDialog"/>
    <EuiGrid gutters>
      <!-- **TOOLBAR** -->
      <EuiGridRow justify="between" align="stretch">
        <EuiGridColumn>
          <div class="eui-u-flex eui-u-flex-wrap items-center">
            <EuiHeading :level="1" bold>{{ selectedTab.text }}</EuiHeading>
            <div class="flex flex-1 justify-end">
              <EuiButton variant="raised" color="primary" @click="$router.push({ name: 'paraphers.create.addParameters' })">
                {{ $t('signworkflows.create-signworkflow') }}
              </EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('signworkflows.block.title')">
            <EuiGrid>
              <EuiGridRow>
                <EuiGridColumn>
                  <EuiHeading :level="4" v-if="signworkflows.length > 0" class="flex items-center h-full">
                      <template v-if="selected.length >0">{{ $tc('signworkflows.selected', selected.length, { count: selected.length }) }}</template>
                      <template v-else>{{ $tc('signworkflows.nbParaphers', nbSignbooks, { count: nbSignbooks }) }}</template>
                  </EuiHeading>
<!--
                        <EuiListItem v-if="!isDeskRhDomain || (isDeskRhDomain && deskRhFeatures.includes('ParaphCreateUpdate'))" :button="true" @click="deleteMultiple(selected)">
-->
                </EuiGridColumn>
                <EuiGridColumn width="1/3">
                  <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                            v-model.trim="searchInput"
                            @keyup.enter="searchSignworkflow()">
                    <template #endEnhancer>
                      <div class="eui-m-cardFilter__clear">
                        <template v-if="searchInput && searchInput.length > 0">
                          <EuiButton iconOnly size="small" color="primary" @click.prevent.stop="onClear">
                            <EuiIcon name="close"/>
                          </EuiButton>
                          <div class="eui-m-cardFilter__separator"></div>
                        </template>
                      </div>
                      <EuiButton iconOnly color="primary" size="small" @click.prevent.stop="searchSignworkflow()">
                        <EuiIcon name="search"/>
                      </EuiButton>
                    </template>
                  </EuiInput>
                </EuiGridColumn>
              </EuiGridRow>
              <EuiGridRow>
                <EuiDropdownMenu v-if="selected.filter(x => x.finalizedDate).length &&
                             selected.filter(x => !x.finalizedDate).length">
                  <template v-slot:anchor>
                    <EuiButton iconOnly color="primary" size="small">
                      <EuiIcon name="more_vert"/>
                    </EuiButton>
                  </template>
                  <EuiList>
                    <EuiListItem v-if="canManageModels" :button="true" @click="setSaveAsTemplateDialogActive({ id: item.signBookId, name: item.name })">{{ $t('button.saveAsTemplate') }}</EuiListItem>
                    <EuiListItem :button="true" @click="deleteMultiple(selected)">
                      {{ $t('button.delete') }}
                    </EuiListItem>
                  </EuiList>
                </EuiDropdownMenu>
              </EuiGridRow>
              <EuiPagination v-if="signworkflows.length"
                             v-model="pagination.current"
                             class="ef-u-mb-20"
                             :displayText="$t('pagination.text')"
                             :totalPages="pagination.total"
                             :itemsPerPage="searchParams.limit"
                             @update:itemsPerPage="numberChange($event)"
                             @change="goToPage($event)"/>
            </EuiGrid>
            <div v-if="signworkflows.length || searchIsOn">
              <EuiDivider secondary />
              <FiltersBar secondary
                          :filters='filters'
                          @clear-filters="clearFilters(clearSearchParamsFromFilter, getSignworkflows)"
                          @delete-filter="deleteFilterToCompute"
                          @update-filter="updateFilterToCompute"/>
              <EuiDivider secondary />
            </div>
            <EuiTooltip :content="$t('signworkflows.toolbar.archive')"
                        placement="top"
                        v-if="selected.filter(x => x.finalizedDate).length &&
                               !selected.filter(x => !x.finalizedDate).length">
              <template slot="anchor">
                <EuiButton @click="archiveMultiple(selected)"
                           iconOnly
                           color="primary">
                  <EuiIcon name="classeur"/>
                </EuiButton>
              </template>
            </EuiTooltip>
            <EuiTooltip :content="$t('button.signAll')"
                        placement="top"
                        v-if="canSign">
              <template slot="anchor">
                <EuiButton iconOnly
                           @click="signAll(selected)">
                  <EuiIcon
                    name="sign" color="primary"
                  />
                </EuiButton>
              </template>
            </EuiTooltip>
            <EuiTooltip :content="$t('button.delete')"
                        placement="top"
                        v-if="tab !== 'completed' && tab !== 'archived' && selected.length">
              <template slot="anchor">
                <EuiButton iconOnly
                           @click="deleteMultiple(selected)">
                  <EuiIcon
                    name="delete" color="primary"
                  />
                </EuiButton>
              </template>
            </EuiTooltip>
            <EuiAdvancedTable v-if="user && signworkflows.length > 0" v-model="signworkflows" :selectedValue.sync="selected">
              <template #tableHead>
                <EuiTableHead v-for="item in sortHeaders" :key="item.field">
                  <div class="eui-u-flex">
                    {{ item.name }}
                    <SubMenu :field="item.field"
                             :name="item.name"
                             :filterType="item.filter"
                             :moveColumns="true"
                             @addFilter="addFilter($event, computeFilter, getSignworkflows)"
                             @moveToLeft="move(item.field, 'left', signworkflowsVueOrder, headers, setSignworkflowsVueOrderAction)"
                             @moveToRight="move(item.field, 'right', signworkflowsVueOrder, headers, setSignworkflowsVueOrderAction)"/>
                  </div>
                </EuiTableHead>
                <EuiTableHead />
              </template>
                <EuiATableRow selectable="multiple" slot="tableRow" slot-scope="{ item }" :key="item.signBookId" @click.native="goToNextPage(item)">
                  <EuiTableCell v-for="(header, index) in sortHeaders" :key="index">
                    <div>{{ checkHeaderField(header, item) }}</div>
                  </EuiTableCell>
                  <EuiTableCell dataType="button">
                    <EuiDropdownMenu v-if="item.finalizedDate && selectedTab.value !== 'archived'">
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                      <EuiList>
                        <EuiListItem :button="true" @click.stop="getArchive([item.signBookId, item.name])">{{ $t('button.download') }}</EuiListItem>
                        <EuiListItem v-if="canManageModels" :button="true" @click.stop="setSaveAsTemplateDialogActive({ id: item.signBookId, name: item.name })">{{ $t('button.saveAsTemplate') }}</EuiListItem>
                        <EuiListItem :button="true" @click.stop="archiveSignworkflow(item)">
                          {{ $t('signworkflows.toolbar.archive') }}
                        </EuiListItem>
                      </EuiList>
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                    </EuiDropdownMenu>
                    <EuiDropdownMenu v-else-if="!item.finalizedDate &&
                                      item.nextSignerName === user.fullname &&
                                      Date.parse(item.nextStepDate) > Date.now()">
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                      <EuiList>
                        <EuiListItem :button="true" @click.stop="$router.push({ name: 'guestSignworkflows.sign', params: { signBookId: item.signBookId }})">{{ $t('signworkflows.toolbar.sign') }}</EuiListItem>
                        <EuiListItem :button="true" @click.stop="refuseDialog.push(item)" v-if="isTrue(item.allowDecline)">{{ $t('signworkflows.toolbar.refuse') }}</EuiListItem>
                        <EuiListItem v-if="canManageModels" :button="true" @click.stop="setSaveAsTemplateDialogActive({ id: item.signBookId, name: item.name })">
                          {{ $t('button.saveAsTemplate') }}
                        </EuiListItem>
                        <EuiListItem :button="true" @click.stop="getArchive([item.signBookId, item.name])">
                          {{ $t('button.download') }}
                        </EuiListItem>
                        <EuiListItem :button="true" @click.stop="deleteSignworkflow(item)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                    </EuiDropdownMenu>
                    <EuiDropdownMenu v-else-if="(!item.finalizedDate &&
                    item.nextSignerName !== user.fullname) ||
                    Date.parse(item.nextStepDate) < Date.now()">
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                      <EuiList>
                        <EuiListItem :button="true" @click.stop="getArchive([item.signBookId, item.name])">
                          {{ $t('button.download') }}
                        </EuiListItem>
                        <EuiListItem v-if="canManageModels" :button="true" @click.stop="setSaveAsTemplateDialogActive({ id: item.signBookId, name: item.name })">
                          {{ $t('button.saveAsTemplate') }}
                        </EuiListItem>
                        <EuiListItem :button="true" @click.stop="goToNextPage(item)">
                          {{ $t('button.edit') }}
                        </EuiListItem>
                        <EuiListItem v-if="!isDeskRhDomain || (isDeskRhDomain && deskRhFeatures.includes('ParaphCreateUpdate'))" :button="true" @click.stop="deleteSignworkflow(item)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                    </EuiDropdownMenu>
                    <EuiDropdownMenu v-else-if="(tab === 'archived')">
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                      <EuiList>
                        <EuiListItem :button="true" @click.stop="getArchive([item.signBookId, item.name])">
                          {{ $t('button.download') }}
                        </EuiListItem>
                        <EuiListItem v-if="!isDeskRhDomain || (isDeskRhDomain && deskRhFeatures.includes('ParaphCreateUpdate'))" :button="true" @click.stop="deleteSignworkflow(item)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                    </EuiDropdownMenu>
                  </EuiTableCell>
                </EuiATableRow>
              <template #tableEmptyState>
                <p class="es-subtitle">{{ $t('signworkflows.create.dialog.organizations.noMatch') }}</p>
              </template>
            </EuiAdvancedTable>
            <EuiPagination v-if="signworkflows.length"
                           v-model="pagination.current"
                           class="ef-u-mb-20"
                           :displayText="$t('pagination.text')"
                           :totalPages="pagination.total"
                           :itemsPerPage="searchParams.limit"
                           @update:itemsPerPage="numberChange($event)"
                           @change="goToPage($event)"/>
            <div v-if="signworkflows.length === 0">
              <p>{{ $t('signworkflows.table.none') }}</p>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import preferences from '../../utils/preferences';
import SubMenu from '../../components/table/SubMenu';
import FiltersBar from '../../components/table/FiltersBar';
import tableFilters from '../../mixins/tableFilters';
import tableGlobalActions from '../../mixins/tableGlobalActions';
import refuseDialog from './dialog/refuseDialog';
import SaveAsTemplateDialog from './dialog/saveAsTemplateDialog';
import axios from 'axios';

export default {
  name: 'Signworkflows',
  props: {
    noReset: {
      type: Boolean,
      require: false,
      default: false,
    },
    tab: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    'es-refuse-dialog': refuseDialog,
    SaveAsTemplateDialog,
    SignDialog: () => import(/* webpackPrefetch: true */ '../../pages/signworkflows/dialog/signature/SignDialog'),
    SubMenu,
    FiltersBar,
  },
  mixins: [tableFilters, tableGlobalActions],
  data() {
    return {
      headers: [
        { name: this.$i18n.t('signworkflows.table.title'), field: 'name', filter: ['sortable'] },
        { name: this.$i18n.t('signworkflows.table.status'), field: 'status' },
        { name: this.$i18n.t('signworkflows.table.deadline'), field: 'deadline' },
        { name: this.$i18n.t('signworkflows.table.creator'), field: 'owner' },
        { name: this.$i18n.t('signworkflows.table.lastReminder'), field: 'lastReminder' },
        { name: this.$i18n.t('signworkflows.table.remindedRecipients'), field: 'alertedRecipients' },
        { name: this.$i18n.t('signworkflows.table.creationDate'), field: 'creationDate', filter: ['date'] },
      ],
      signDialog: [],
      // search arams
      searchParams: {},
      // seach bar
      searchIsOn: false,
      searchInput: '',
      deadlineSearchOn: false,
      // select
      selected: [],
      // calendar view
      calendar: undefined,
      selectedMonth: {
        year: undefined,
        month: undefined,
      },
      // dialog
      saveAsTemplateDialogActive: '',
      refuseDialog: [],
      // aside menu
      selectedTab: '',
      tabs: [
        {
          icon: 'inbox',
          value: 'inbox',
          text: this.$i18n.t('signworkflows.tab.inbox'),
          notif: 0,
        },
        {
          icon: 'sent',
          value: 'sent',
          text: this.$i18n.t('signworkflows.tab.sent'),
          notif: 0,
        },
        {
          icon: 'archive',
          value: 'archived',
          text: this.$i18n.t('signworkflows.tab.archive'),
          notif: 0,
        },
        {
          icon: 'a_valider',
          value: 'waiting-for-action',
          text: this.$i18n.t('signworkflows.status.to-validate'),
          notif: 0,
        },
        {
          icon: 'pending',
          value: 'waiting-for-others',
          text: this.$i18n.t('signworkflows.status.waiting-for-others'),
          color: 'light-blue',
          notif: 0,
        },
        {
          icon: 'expired_soon',
          value: 'expire-soon',
          text: this.$i18n.t('signworkflows.status.expire-soon'),
          notif: 0,
        },
        {
          icon: 'expired',
          value: 'expired',
          text: this.$i18n.t('signworkflows.status.expired'),
          color: 'red',
          notif: 0,
        },
        {
          icon: 'completed',
          value: 'completed',
          text: this.$i18n.t('signworkflows.status.completed'),
          color: 'green',
          notif: 0,
        },
      ],
      canManageModels: true,
      filters: [],
    };
  },
  computed: {
    ...mapState({
      nbSignbooks: (state) => state.ModuleEdocSign.parapheurs.nbSignbooks,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
    hasParaphs() {
      let result = false;
      this.selected.forEach(x => x.steps.forEach(y => y.captiveZones.forEach(z => {
        if (z.zoneType === 'paraph') result = true;
      })));
      return result;
    },
    hasSigns() {
      let result = false;
      this.selected.forEach(x => x.steps.forEach(y => y.captiveZones.forEach(z => {
        if (z.zoneType === 'signature') result = true;
      })));
      return result;
    },
    hasTextZones() {
      let result = false;
      this.selected.forEach(x => x.steps.forEach(y => y.captiveZones.forEach(z => {
        if (z.zoneType === 'textfield') result = true;
      })));
      return result;
    },
    canSign() {
      return !this.selected.filter(x => x.finalizedDate).length &&
        this.selected.filter(x => !x.finalizedDate).length &&
        !this.hasTextZones &&
        this.selected.filter(x => x.nextSignerName === this.user.fullname).length > 0;
    },
    ...mapGetters({
      storeSearchParams: 'ModuleEdocSign/parapheurs/searchParams',
      signworkflows: 'ModuleEdocSign/parapheurs/signworkflows',
      pagination: 'ModuleEdocSign/parapheurs/pagination',
      user: 'ModuleEdocSign/auth/user',
      isUserHasEmail: 'ModuleEdocSign/auth/isUserHasEmail',
      usageDatas: 'ModuleEdocSign/auth/usageDatas',
      options: 'ModuleEdocSign/auth/options',
      profile: 'ModuleEdocSign/auth/profile',
      signworkflowsVueOrder: 'ModuleEdocSign/preferences/signworkflowsVueOrder',
    }),
    isOpen: {
      get() {
        return this.signDialog && this.signDialog.length > 0;
      },
      set(value) {
        if (!value) {
          this.selected = [];
          this.signDialog = [];
        }
      },
    },
    deskRhFeatures() {
      return JSON.parse(localStorage.getItem('deskRhFeatures'));
    },
    selectAll: {
      get() {
        return this.signworkflows ? this.selected.length === this.signworkflows.length : false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.signworkflows.forEach((user) => {
            selected.push(user);
          });
        }
        this.selected = selected;
      },
    },
    sortHeaders() {
      this.getSignworkflowsVueOrderAction();
      if (this.signworkflowsVueOrder === null) return this.headers;
      return preferences.updateSortedHeaders(this.signworkflowsVueOrder, this.headers, this.setSignworkflowsVueOrderAction);
    },
  },
  watch: {
    $route(to, from) {
      this.onRoutechange();
    },
  },
  methods: {
    ...mapActions({
      setLastBreadcrumb: 'application/setLastBreadcrumb',
      storeArchiveSignworkflow: 'ModuleEdocSign/parapheurs/archiveSignworkflow',
      storeDeleteSignworkflow: 'ModuleEdocSign/parapheurs/deleteSignworkflow',
      getArchive: 'ModuleEdocSign/parapheurs/getArchive',
      storeGetSignworkflows: 'ModuleEdocSign/parapheurs/getSignworkflows',
      resetSearchParams: 'ModuleEdocSign/parapheurs/resetSearchParams',
      getUserInfos: 'ModuleEdocSign/auth/getUserInfos',
      getUsageDatas: 'ModuleEdocSign/auth/getUsageDatas',
      pushAlert: 'application/pushAlert',
      setSignworkflowsVueOrderAction: 'ModuleEdocSign/preferences/setSignworkflowsVueOrder',
      getSignworkflowsVueOrderAction: 'ModuleEdocSign/preferences/getSignworkflowsVueOrder',
    }),

    getSignworkflows() {
      this.storeGetSignworkflows(this.searchParams);
    },

    isTrue(boolean) {
      return (boolean || boolean === 'true' || boolean === '1' || boolean === 1);
    },

    checkHeaderField(header, item) {
      switch (header.field) {
        case 'owner':
          return item.owner.name;
        case 'status':
          return this.getSignworkflowsStateText(item);
        case 'deadline':
          return this.$d(new Date(item.endingDate), 'short');
        case 'lastReminder':
          return this.$d(item.lastAlertDate, 'short');
        case 'creationDate':
          return this.$d(new Date(item.creationDate), 'short');
        default:
          return item[header.field];
      }
    // <div v-if="header.field === 'owner'">
    //     {{ item.owner.name }}
    // </div>
    //   <div v-else-if="header.field === 'status'">
    //     {{ getSignworkflowsStateText(item) }}
    // </div>
    //   <div v-else-if="header.field === 'deadline'">
    //     {{ $d(new Date(item.endingDate), 'short') }}
    // </div>
    //   <div v-else-if="header.field === 'lastReminder'">
    //     {{ $d(item.lastAlertDate, 'short') }}
    // </div>
    //   <div v-else-if="header.field === 'creationDate'">
    //     {{ $d(new Date(item.creationDate), 'short') }}
    // </div>
    //   <div v-else>
    //   {{ item[header.field] }}
    // </div>
    },

    setSearchParams(creationDateParam, searchTag) {
      const paramStartIndex = this.searchParams.params.findIndex(param => param.paramName === 'creationDateStart');
      const paramEndIndex = this.searchParams.params.findIndex(param => param.paramName === 'creationDateEnd');
      if (creationDateParam === 'creationDate') {
        if (paramStartIndex > 0) {
          this.searchParams.params[paramStartIndex] = { paramName: 'creationDateStart', paramValue: `${searchTag.creationDateStart} 00:00:00` };
        } else {
          this.searchParams.params.push({ paramName: 'creationDateStart', paramValue: `${searchTag.creationDateStart} 00:00:00` });
        }
        if (paramEndIndex > 0) {
          this.searchParams.params[paramEndIndex] = { paramName: 'creationDateEnd', paramValue: `${searchTag.creationDateEnd} 23:59:59` };
        } else {
          this.searchParams.params.push({ paramName: 'creationDateEnd', paramValue: `${searchTag.creationDateEnd} 23:59:59` });
        }
      } else {
        if (paramStartIndex > 0) {
          this.searchParams.params[paramStartIndex] = { paramName: creationDateParam, paramValue: `${searchTag[creationDateParam]} 00:00:00` };
        } else {
          this.searchParams.params.push({ paramName: creationDateParam, paramValue: `${searchTag[creationDateParam]} 00:00:00` });
        }
      }
    },

    computeFilter() {
      this.resetPagination();
      this.filters.forEach((filter) => {
        filter.searchTags.forEach((searchTag) => {
          if (filter.field === 'creationDate') {
            if (searchTag.creationDateEnd === undefined) {
              this.setSearchParams('creationDateStart', searchTag);
            } else if (searchTag.creationDateStart === undefined) {
              this.setSearchParams('creationDateEnd', searchTag);
            } else {
              this.setSearchParams('creationDate', searchTag);
            }
            this.searchParams.allowToFilterByDate = true;
          } else {
            const paramIndex = this.searchParams.params.findIndex(param => param.paramName === filter.field);
            if (paramIndex < 0) {
              this.searchParams.params.push({ paramName: filter.field, paramValue: searchTag });
            } else {
              this.searchParams.params[paramIndex] = { paramName: filter.field, paramValue: searchTag };
            }
          }
        });
        if (filter.sort.length > 0) {
          this.searchParams.orders[0] =
            {
              fieldName: filter.field,
              orderBy: filter.sort[0],
            };
        }
      });
    },

    clearSearchParamsFromFilter(filter) {
      this.searchParams.allowToFilterByDate = false;
      if (filter.sort.length > 0) {
        this.searchParams.orders = [{ fieldName: 'creationDate', orderBy: 'DESC' }];
      }
      if (filter.field === 'creationDate') {
        this.searchParams.params = this.searchParams.params.slice(0, 1);
      } else {
        this.searchParams.params.splice(this.searchParams.params.findIndex(param => param.paramValue === filter.searchTags[0]), 1);
      }
    },

    updateFilterToCompute(newFilters) {
      this.updateFilter(newFilters, this.computeFilter, this.getSignworkflows);
    },

    deleteFilterToCompute(field) {
      this.deleteFilter(field, this.clearSearchParamsFromFilter, this.getSignworkflows);
    },

    numberChange(number) {
      this.searchParams.limit = number;
      this.getSignworkflows(this.searchParams);
    },

    setSaveAsTemplateDialogActive(value) {
      this.saveAsTemplateDialogActive = value;
    },
    closeSaveAsTemplateDialog() {
      this.saveAsTemplateDialogActive = '';
    },
    signAll(selected) {
      this.signDialog = selected;
    },
    closeDialog() {
      if (this.selected.length === 0) {
        this.allSelected = false;
      }
      this.signDialog = [];
      this.refuseDialog = [];
    },
    getSignworkflowsStateText(item) {
      const finalizedDate = item.finalizedDate
        ? item.finalizedDate.split(' ')[0].split('-').reverse().join('/') : undefined;
      const nextStepDate = item.nextStepDate
        ? item.nextStepDate.split(' ')[0].split('-').reverse().join('/') : undefined;

      let text = {};

      if (item.finalizedDate && !item.steps.find((x) => parseInt(x.refuted))) {
        text = this.$i18n.t('signworkflows.table.state.completed', { date: finalizedDate });
      } else if (item.finalizedDate && item.steps.find((x) => parseInt(x.refuted))) {
        const refusedSigner = item.steps[item.nbStepsRealized - 1].signerName;
        text = this.$i18n.t('signworkflows.table.state.refused', { person: refusedSigner, date: finalizedDate });
      } else if (new Date(item.endingDate) < Date.now()) {
        text = this.$i18n.t('signworkflows.table.state.expired', { person: item.nextSignerName, date: nextStepDate });
      } else if (item.nextSignerName && item.steps.find(step => step.isNextStep).userId !== this.user.userId) {
        text = this.$i18n.t('signworkflows.table.state.pending', { person: item.nextSignerName, date: nextStepDate });
      } else {
        text = this.$i18n.t('signworkflows.table.state.waiting-for-action', { date: nextStepDate });
      }
      return text;
    },
    onClear() {
      this.searchParams.params.splice(this.searchParams.params.findIndex((x) => x.paramName === 'name'), 1);
      this.searchInput = '';
      this.searchSignworkflow();
    },
    goToNextPage(item) {
      if (!item.finalizedDate &&
          item.nextSignerName && item.steps.find(step => step.isNextStep).userId === this.user.userId &&
        Date.parse(item.nextStepDate) > Date.now()) {
        this.$router.push({ name: 'guestSignworkflows.sign', params: { signBookId: item.signBookId } });
      } else {
        this.$router.push({ name: 'signworkflow.edit', params: { signBookId: item.signBookId } });
      }
    },
    // give signworkflows state
    searchSignworkflow() {
      this.searchParams.params = this.searchParams.params.filter((el) => el.paramName !== 'name');
      this.searchParams.params.push({
        paramName: 'name', paramValue: this.searchInput,
      });
      this.searchParams.offset = 0;
      this.getSignworkflows(this.searchParams);
    },
    archiveSignworkflow(item) {
      this.storeArchiveSignworkflow({ signBookId: item.signBookId })
        .then(() => {
          this.getSignworkflows(this.searchParams);
          this.getUsageDatas();
        });
    },
    deleteSignworkflow(item) {
      this.storeDeleteSignworkflow({ signBookId: item.signBookId })
        .then(() => {
          this.getSignworkflows(this.searchParams);
          this.getUsageDatas();
        });
    },
    archiveMultiple(list) {
      const promises = [];
      for (let i = 0; i < list.length; i++) {
        promises.push(this.storeArchiveSignworkflow({ signBookId: list[i].signBookId }));
      }
      if (promises.length) {
        axios.all(promises)
          .then(() => {
            if (this.selected.length === 0) {
              this.allSelected = false;
            }
            this.selected = [];
            this.getSignworkflows(this.searchParams);
            this.getUsageDatas();
          });
      } else {
        this.pushAlert({
          message: this.$i18n.t('store.signworkflows.archive.incompatible'),
          type: 'error',
        });
      }
    },
    deleteMultiple(list) {
      const promises = [];
      for (let i = 0; i < list.length; i++) {
        if (!(list[i].nbStepsRealized >= list[i].nbSteps)) {
          promises.push(this.storeDeleteSignworkflow({ signBookId: list[i].signBookId }));
        }
      }
      if (promises.length) {
        axios.all(promises)
          .then(() => {
            if (this.selected.length === 0) {
              this.allSelected = false;
            }
            this.selected = [];
            this.getSignworkflows(this.searchParams);
            this.getUsageDatas();
          });
      } else {
        this.pushAlert({
          text: this.$i18n.t('store.signworkflows.delete.incompatible'),
          type: 'error',
        });
      }
    },
    changeTab(tab) {
      if (this.allSelected) {
        this.selectAll();
      }
      this.selectedTab = tab;
      this.searchParams.params[0].paramValue = tab.value;
      this.searchParams.offset = 0;
      this.getSignworkflows(this.searchParams);
    },
    goToName(name) {
      this.$router.push({ name });
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.getSignworkflows(this.searchParams);
    },
    // change the month selector for signbooks
    selectMonth(year, month) {
      // set seleted month
      this.selectedMonth.year = year;
      this.selectedMonth.month = month;

      this.searchParams.offset = 0;

      this.getSignworkflows(this.searchParams).then(() => {
        this.calendarView = false;
      });
    },
    getDateClass(year, month, day) {
      const firstDay = this.getFirstDay(year, month);
      return [
        'es-calendar__month__date',
        {
          'es-calendar__month__date--hidden': day < firstDay,
        },
      ];
    },
    /** return a number between 1-7 to indicate the first day of the input month and year
    *   1 --> monday
    *   7 --> sunday
    **/
    getFirstDay(year, month) {
      return new Date(year, month - 1, 1).getDay() === 0 ? 7 : new Date(year, month - 1, 1).getDay();
    },
    // build the last 12 months calendar object from current date
    /** build searchParams from month number
    * int number, the month number
    * Boolean pastYear, if the year is the current (false) or past (true)
    **/
    resetParams() {
      this.resetSearchParams();
      this.searchParams = JSON.parse(JSON.stringify(this.storeSearchParams));
      this.selectedMonth.year = this.calendar.currentYear.number;
      this.selectedMonth.month = this.calendar.currentYear.months[0].number;
    },
    onRoutechange() {
      this.filters = [];
      this.resetSearchParams();
      this.selected = [];
      if (this.$route.params.tab) {
        this.selectedTab = this.tabs.find((x) => x.value === this.$route.params.tab);
      } else {
        this.selectedTab = this.tabs.find((x) => x.value === this.storeSearchParams.params[0].paramValue);
      }
      this.setLastBreadcrumb(this.selectedTab.text);
      this.searchParams = JSON.parse(JSON.stringify(this.storeSearchParams));
      this.searchParams.params[0].paramValue = this.selectedTab.value === 'archived' ? 'deleted' : this.selectedTab.value;
      this.getSignworkflows(this.searchParams);
      if (this.user === undefined && this.isUserHasEmail) {
        this.getUserInfos();
      }
      if (this.usageDatas === undefined && this.isUserHasEmail) {
        this.getUsageDatas();
      }

      if (this.options && this.options.manageSignbookModels) {
        this.canManageModels = parseInt(this.options.manageSignbookModels.value);
      }

      if (this.canManageModels) {
        this.canManageModels = this.$store.state.ModuleEdocSign.auth.profile.features && this.$store.state.ModuleEdocSign.auth.profile.features.hasWriteModelPermission === '1';
      }
    },
  },
  mounted() {
    this.onRoutechange();
    this.getSignworkflowsVueOrderAction();
  },
};
</script>
