<template>
  <EuiDialog @onClose="$emit('closeDialog')" :open="true" size="medium">
    <template v-slot:header>
      {{ $t('signworkflows.refuse.title') }}
    </template>
      <!-- signature code zone -->
      <EuiHeading :level="4" bold>{{ $tc('signworkflows.refuse.text', signworkflows.length) }}</EuiHeading>
      <form @submit.prevent="refuseSignature(signworkflows)" id="refuseSignatureForm">
          <EuiTextField adaptSize class="mt-2" noResize>
            <EuiLabel>{{ $t('signworkflows.refuse.reason') }}</EuiLabel>
            <EuiTextarea fullWidth :rows="2" v-model.trim="refutalReason"/>
          </EuiTextField>
      </form>
    <template v-slot:footer>
      <!--<EuiButton color="primary" variant="text" @click.prevent="$emit('closeDialog')">
        {{ $t('button.cancel') }}
      </EuiButton>-->
      <EuiButton color="primary" variant="raised" type="submit" form="refuseSignatureForm" :disabled="refutalReason === ''">
        {{ $t('button.validate') }}
      </EuiButton>
    </template>
  </EuiDialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import DOMpurify from 'dompurify';

export default {
  name: 'RefuseDialog',
  props: {
    signworkflows: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      refutalReason: '',
    };
  },
  computed: {
    ...mapGetters({
      searchParams: 'ModuleEdocSign/parapheurs/searchParams',
    }),
  },
  methods: {
    ...mapActions({
      storeRefuseSignature: 'ModuleEdocSign/parapheurs/refuseSignature',
      getSignworkflows: 'ModuleEdocSign/parapheurs/getSignworkflows',
      getUsageDatas: 'ModuleEdocSign/auth/getUsageDatas',
    }),
    refuseSignature(list) {
      const promises = [];
      for (let i = 0; i < list.length; i++) {
        if (!(list[i].nbStepsRealized >= list[i].nbSteps)) {
          promises.push(this.storeRefuseSignature({ signBookId: list[i].signBookId, refutalReason: DOMpurify.sanitize(this.refutalReason) }));
        }
      }
      axios.all(promises)
        .then(() => {
          if (this.$route.name === 'guestSignworkflows' || !this.$store.state.ModuleEdocSign.auth.session.guest) {
            this.getSignworkflows(this.searchParams)
              .then(() => {
                this.getUsageDatas()
                  .then(() => {
                    this.$emit('closeDialog', true);
                    if (!this.$store.state.ModuleEdocSign.auth.session.guest) {
                      this.$router.push({ path: '/signworkflows/waiting-for-action' });
                    }
                  });
              });
          } else {
            this.$router.push({ name: 'guestSignworkflows' });
          }
        });
    },
  },
};
</script>
