<template>
  <EuiDialog size="small" :open="signBookId.length > 0" @onClose="close">
    <template #header>{{ $t('saveAsTemplate.title') }}</template>
    {{ $t('templates.create.TemplateName.title') }}
    <EuiInput type="text" v-model="template.name" required/>
    <template #footer>
      <EuiButton variant="raised" color="primary" @click="saveAsTemplate">{{ $t('button.save') }}</EuiButton>
      <EuiButton @click="close">{{ $t('button.cancel') }}</EuiButton>
    </template>
  </EuiDialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  /**
   * The name of the page.
   */
  name: 'SaveAsTemplateDialog',
  props: {
    signBookId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      template: {
        signBookId: '',
        name: this.name,
      },
    };
  },
  /**
   * The components that the page can use.
   */
  components: {
  },
  methods: {
    ...mapActions({
      transformToTemplate: 'ModuleEdocSign/parapheurs/transformToTemplate',
    }),
    saveAsTemplate() {
      this.template.signBookId = this.signBookId;
      this.transformToTemplate(this.template).then(() => {
        this.close();
        this.$router.push({ name: 'templates.all' });
      }).catch((error) => {
        console.log(error);
      });
    },
    close() {
      this.$emit('change', false);
      this.$emit('close');
    },
    goBack() {
      this.$router.push({ name: this.$store.state.route.from.name });
    },
  },
};
</script>
